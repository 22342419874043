import React from "react"
import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import { VideoPlayer } from "@components/video"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const TStyleGuitar = () => {

  return (
    <PageLayout>
      <SEO 
          title="T-Style Guitar | Taurus | King Electric Guitars"
          ogDescription="The Taurus is an T-style guitar from King Electric Guitars with a wide variety of customization options and finishes." 
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>
              T-Style Guitar - Taurus
            </h1>
            <p>
              The T-style electric guitar model is a popular choice for a lot of players. Danny Gatton, Keith Richards, Terry Kath, Jeff Buckley, Bruce Springsteen, and D Boon to name a few. I make a T-style guitar that I call the Taurus (it's my sign) ... with a few significant changes.
            </p>
            <p>
              I always loved the T-style guitar body, but they can be a little uncomfortable to play because of the sharp edges. So, I add body contouring which makes it more comfortable. I also altered the body shape. It’s a nod to the classic Fender Telecaster style guitar with a few asymmetrical changes. It's a little longer and offset. It’s a little bit wider in some places, but the waste is a little narrower. It reminds me of the jacked-up ’79 Camaro my childhood neighbor owned. I've always been into cars. His Camaro and his triple stack of Marshalls somehow got mixed up in my mind. And that influence creeps into all my guitar designs.
            </p>
            <p>Starting at <span className="green bold">$2100</span></p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/taurus-randy.jpg"
              alt="T-Style Guitar Taurus - King Electric Guitars"
            />
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img
                src="/images/taurus-headstock.jpg"
                alt="Guitar Body Shapes - King Electric Guitars"
              />
            </div>
          }
          textRight={
        <div>
          <h2>
            Headstock
          </h2>
          <p>
            The headstock is my own headstock design. I do a lot of reverse headstocks. They're cool. But, I can also make it a non-reverse headstock. The standard headstock on the Taurus is a Fender-style scooped-out flat headstock with string trees. Or for a little more money, I can make it an 8-degree tilt-back headstock more like a Gibson.
          </p>
            
          <h2>
            T-Style Custom Guitar Necks
          </h2>
          <p>
            The Taurus comes standard with a maple neck. But almost anything is possible. I could do mahogany, a multi-laminate, roasted maple. There are a lot of different neck options.
          </p>
          <h3>
            Custom Neck Feel
          </h3>
          <p>
            If you're local to me and can come to the shop, you can stop by and feel a few different neck cuts to get an idea of what you like. I'll do a rough carve of your neck based on that initial meeting. Then you'll come back in and we can tweak it while you're here. The result is a neck that perfectly fits your hand and playing style.
          </p>
        </div>
          }
        />
      </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>

          </div>
        }
      /> 
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>
              Fretboard
            </h3>
            <p>
              Fingerboard options can be made from Maple, Katalox, Ebony, Brazilian rosewood, Bocote, or almost any hardwood you want.
            </p>
            <h3>
              Radius
            </h3>
            <p>
              My standard fretboard radius is 12 inches. That's a little flatter than standard Fenders but closer to a Gibson radius. If you prefer a flatter or rounder radius, I can do that as well.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/taurus-fretboard.jpg"
              alt="Taurus T-Style Guitar Neck Construction and Fretboard Radius"
            />
          </div>
        }
      />
      <PageWrapper color="grey">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>
                Bound Arm-Carve Top
              </h2>
              <p>
                Most guitars you see with an arm carve are not bound. But I have a jig that I made that allows me to keep that binding square and drop down and follow the body. So that's a standard option on the Taurus - a bound arm carve top.
              </p>
              <h2>
                True Bolt-on Guitar Neck
              </h2>
              <p>
                My bolt-on necks are true bolt-on. I use ferrule inserts and machine screws. It's not just screwed into wood that'll strip out and cause the neck to give. I design using what I learned in repair. The biggest problem with a bolt-on neck is that they're sloppy and they don't tighten all the way up. The inserts pull that neck in so tight it's like I glued it. The tighter that connection between neck and body, the more everything is resonating. The advantage of being bolt-on makes it easier and safer to work on in the future, as well as easier to set up for different bridges.
              </p>
              <h2>
                Sculpted Neck and Body Joint
              </h2>
              <p>
                The Telecaster design has a big chunk of wood at the neck heel. I always felt was an unnecessary limitation to playability. The Taurus design corrects this with a sculpted neck and body joint. This provides better playability high up on the neck.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/taurus-bound-arm-carve-bolt-on.png"
                alt="T-style Bound Arm-Carve Top and True Bolt On Neck"
              />
            </div>
          }
        />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/guitar-pickups-telecaster.jpg"
              alt="Guitar Body Shapes - King Electric Guitars"
            />
          </div>
        }
        textRight={
      <div>
        <h2>
          TELECASTER PICKUPS
        </h2>
        <h3>
          Classic Telecaster Bridge Pickup
        </h3>
        <p>
          The original Tele bridge single coil pickups evolved from a design Leo used for lap steel guitar. Because it was wider than the strings of the electric, he slanted the pickup. The bridge pickup also has a large base plate. This creates an unusual pickup pattern compared to many other single-coil designs. The pickup mounts through the Telecaster bridge plate - referred to as an ashtray bridge.
        </p>
        <h3>
          Classic Telecaster Neck Pickup
        </h3>
        <p>
          Many people assume a Telecaster neck pickup to be a "lipstick" pickup. It isn't. The Telecaster neck pickup is a 6-pole pickup with a metal cover over it. Because it is a narrower size than most single coils, it uses lower gauge wire to fit the winds in.
        </p>
      </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>
              Telecaster Deluxe Humbuckers
            </h3>
            <p>
              Fender introduced the Telecaster Deluxe in the early 1970s. They replaced the Telecaster pickups with dual "wide range" humbuckers developed by Seth Lover. Often you will see modern Deluxe-style builds with dual PAF-style humbuckers as well. 
            </p>
            <p>
              But you can put any pickups you like on a tele-style body. Many of my builds have featured dual P-90 pickups, which is one of my favorite configurations. I've also done builds that combine the vintage Telecaster bridge with a Filter'Tron neck found on Gretsch guitars. There are no rules. The T-style lends itself to a lot of options. 
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>
              Taurus Guitar Bridge Options
            </h2>
            <p>
              The traditional Telecaster bridge is rectangular with the pickup going through the plate. Tele traditionalists often claim the classic bridge with the 3 saddles is a major part of the Tele sound. The thickness of the bridge and the material from which its made can also affect the tone. If you want a standard three-barrel bridge, I recommend one with brass compensated saddles for better intonation. If you plan to use alternate tunings, you want to go with a 6-saddle Tele bridge so you can intonate the guitar.
            </p>
            <p>
              I've found the bridge on traditional Telecasters to be a little too far forward. I move it back enough to provide a little more travel, especially when it comes to people who want to tune down.
            </p>
            <p>
              But once you move away from using a Tele-style bridge pickup, you have to make changes to the bridge. In these cases, you'll often use a smaller fixed-style "hardtail" bridge. The Hipshot hardtail works well paired with dual P-90 pickups.
            </p>
          </div>
        }
        textRight={
          <div>
            <img
            src="/images/guitar-bridges-tele-style.jpg"
            alt="Telecaster Bridge Options - King Electric Guitars"
            />
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img
                src="/images/taurus-t-style-guitar-finish-options.jpg"
                alt="T-Style Guitar Finish Options - King Electric Guitars"
              />
            </div>
          }
          textRight={
        <div>
          <h2>
            Taurus Finish Options
          </h2>
          <p>
            Finish options on a Taurus are endless. I love doing fabric tops. Going back to the car influence, I'm getting into doing lowrider candy paint jobs. Or we can go natural with an oil finish. As for the final coat, I like using urethane. But if you want lacquer, we can do that as well for a little more. 
          </p>
          <h3>
            Fabric
          </h3>
          <p>
            I love doing fabric tops. The first few Taurus guitars I made had 1960's and 1970's paisley designs. It's a finish that makes a huge impact and brings a lot of style to the guitar. There are endless fabric options. They look beautiful.
          </p>
          <h3>
            Paint
          </h3>
          <p>
            Almost any color or style of paint you can think of is possible. Even car paint. Leo Fender bought car paint from the same manufacturers that made paint for cars at that time. I catch myself daily driving around looking at cars for inspiration. I'm getting into doing lowrider candy paint jobs currently.
          </p>
          <h3>
            Natural and Topcoat
          </h3>
          <p>
            Or we can go natural with an oil finish. We can combine contrasting woods, veneer, and wood bindings to create a unique and beautiful piece.
          </p>
          <p>
            As for the final coat, I like using urethane. But if you want nitrocellulose lacquer, we can do that as well for a little more.
          </p>
          <FormWrapper
            modal
            buttonProps={{
              content: "Reach Out. Let's Talk!",
              size: "tiny",
              icon: "chat",
            }}
          >
            <RequestMoreInfo />
          </FormWrapper>
        </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>
              Electronics
            </h3>
            <p>
              I offer the stock option of reversing the electronics plate. Standard on the Taurus is one with an angled switch selector. There are a few different wiring options we can discuss depending on your pickup selection. Even if you want a single pickup Esquire style, I can do a three-switch <a href="https://sixstringsupplies.co.uk/pages/esquire-eldred-mod" target="_blank">Eldred Mod</a> to give you a wide range of tone options. There are also a few options on the tone capacitor I use. That can make a significant difference in your frequency sweep with the tone knob.
            </p>
            <h3>
              Electro socket
            </h3>
            <p>
              I use electro sockets on the Taurus for my output jack. They solve the problem with the T-style guitars jack coming loose. You have to have a special tool to fix the Teles when that happens. With the Electrosocket, once you screw it in there, it's done.
            </p>
          </div>
        }
        textRight={
      <div>
        <img
          src="/images/guitar-electronics-tone-shaping.jpg"
          alt="T-Style Guitar Electronics - King Electric Guitars"
        />
      </div>
        }
      />
      <div className="section textured" id="videoone">
          <VideoPlayer youtubeId='c6J30ummT4M' />
      </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Get in touch. Let's talk about building your dream guitar!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default TStyleGuitar
